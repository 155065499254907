<template>
  <!--  商城-->
  <div class="shopping">
    <van-nav-bar
      title="商城"
      placeholder
      fixed
      :border="false"
    >
<!--      <template #right>-->
<!--          <p class="van-nav-bar_right_text">实时料价</p>-->
<!--          <p class="van-nav-bar_right_price">￥{{pricebox}}</p>-->
<!--      </template>-->
    </van-nav-bar>
    <img src="@/assets/images/pic_details_top2@2x.png" class="image">
    <!-- 商品信息 -->
    <div class="topcenter">
      <div class="goodsInfo">
        <h1>足金9999投资黄金</h1>
        <div class="condition">9999</div>
        <div class="price">
          <p class="priceText">价格</p>
          <p class="priceNumber">{{(pricebox*100).toFixed(2)}}元</p>
          <button @click="goBuy">立即购买</button>
        </div>
      </div>

      <div class="goodsNextInfo">
        <p>成色:9999</p>
        <p>金重:100g</p>
        <p>库存:500</p>
      </div>

      <div class="goodsDetail">
        <h1>商品详情</h1>
        <img src="@/assets/images/Group 1026@2x.png" alt="">
      </div>

    </div>
    <van-dialog v-model="ceringModel">
<!--      <div class="title">提示</div>-->
      <img src="@/assets/images/shopping_kefu.png" alt="" style="margin-top: 20px">
      <div class="centerbox_dialog">请添加微信客服咨询购买</div>
      <div class="gocer" @click="ceringModel = false"></div>
    </van-dialog>
  </div>
</template>

<script>
import Stomp from "stompjs";

export default {
    data() {
        return {
          // 所有商品数组
          allpro:[],
          // 是否开盘
          trade_: false,
          // 提交时间
          postTime:'',
          // 首次加载的数据
          initDatalist: [],
          firstloadData: true,
          pricebox: '--.--',
          anotherPricebox: '--.--',
          ceringModel: false
        };
    },
  mounted() {
    this.connect();
  },
  destroyed() {
    clearTimeout(this.setInterval)
    this.client.disconnect(function() {
    })
  },
  watch: {
    allpro: function() {
      this.updatearr('商城金条')
    },
  },
    methods:{
      goBuy(){
        this.ceringModel = true
      },
      change(e){
        console.log(e)
      },
      connect() {
        this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
        // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
          login: "username",
          passcode: "password"
        };
        this.client.connect(headers, this.onConnected, this.onFailed);
        this.client.debug = null;
      },
      onConnected() {
        const topic = "/price/all";
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
      },
      responseCallback (frame) {
        this.allpro = []
        const resbody = eval('['+this.unzip(frame.body)+']')[0]
        const parse_res = JSON.parse(JSON.stringify(resbody));
        this.trade_ = resbody.trade;
        this.postTime = resbody.time
        if(this.firstloadData){
          this.initDatalist = resbody
          this.firstloadData = false
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){
              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }else{
          this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){
              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }
        // this.loadingtrue = true
      },
      unzip(b64Data){
        let strData   = atob(b64Data);
        const charData  = strData.split('').map(function(x){return x.charCodeAt(0);});
        const binData   = new Uint8Array(charData);
        const data    = pako.inflate(binData);
        strData   = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
      },
      deepMerge(...objs) {
        const than = this
        const result = Object.create(null)
        objs.forEach(obj => {
          if (obj) {
            Object.keys(obj).forEach(key => {
              const val = obj[key]
              if (than.isPlainObject(val)) {
                // 递归
                if (than.isPlainObject(result[key])) {
                  result[key] = than.deepMerge(result[key], val)
                } else {
                  result[key] = than.deepMerge(val)
                }
              } else {
                //  数组也要重新赋值  不然依然会引用到其他的
                if (Array.isArray(val)) {
                  result[key] = [...val]
                } else {
                  result[key] = val
                }
              }
            })
          }
        })
        return result
      },
      isPlainObject(val) {
        const toString = Object.prototype.toString
        return toString.call(val) === '[object Object]'
      },
      // 更新数组
      updatearr(name){
        if(name == '黄金'){
          this.pricenew('huangjin9999')
          this.productkey = 'huangjin9999'
        }else if(name == '黄金金条999'){
          this.pricenew('huangjin999jt')
          this.productkey = 'huangjin999jt'
        }else if(name == '黄金首饰999'){
          this.pricenew('huangjin999ss')
          this.productkey = 'huangjin999ss'
        }else if(name == '黄金18K 750'){
          this.pricenew('huangjin750')
          this.productkey = 'huangjin750'
        }else if(name == '黄金18K 750'){
          this.pricenew('huangjin750')
          this.productkey = 'huangjin750'
        }else if(name == '商城金条'){
          this.pricenew('market_huangjin')
          this.productkey = 'huangjin9999'
        }else if(name == '铂金'){
          this.pricenew('bojin9996')
          this.productkey = 'bojin9996'
        }else if(name == '铂金首饰999'){
          this.pricenew('bojin999')
          this.productkey = 'bojin999'
        }else if(name == '铂金首饰990'){
          this.pricenew('bojin900')
          this.productkey = 'bojin900'
        }else if(name == '铂金首饰950'){
          this.pricenew('bojin950')
          this.productkey = 'bojin950'
        }else if(name == '铂金(无法确定)'){
          this.pricenew('bojin9996')
          this.productkey = 'bojin9996'
        }else if(name == '钯金'){
          this.pricenew('bajin9996')
          this.productkey = 'bajin9996'
        }else if(name == '钯金首饰999'){
          this.pricenew('bajin999')
          this.productkey = 'bajin999'
          this.pricenew('bajin990')
          this.productkey = 'bajin990'
        }else if(name == '钯金首饰950'){
          this.pricenew('bajin950')
          this.productkey = 'bajin950'
          this.pricenew('bajin9996')
          this.productkey = 'bajin9996'
        }else if(name == '白银'){
          this.pricenew('baiyin9999')
          this.productkey = 'baiyin9999'
        }else if(name == '白银首饰999'){
          this.pricenew('baiyin999')
          this.productkey = 'baiyin999'
        }else if(name == '白银首饰990'){
          this.pricenew('baiyin990')
          this.productkey = 'baiyin990'
        }else if(name == '白银首饰925'){
          this.pricenew('baiyin925')
          this.productkey = 'baiyin925'
        }else if(name == '白银(无法确定)'){
          this.pricenew('baiyin9999')
          this.productkey = 'baiyin9999'
        }
      },
      // 价格变动更新视图
      pricenew(name){
        this.allpro.find((x)=>{
          if(x[name]){
            this.pricebox = x[name]['xiaoshou']
            this.anotherPricebox = x[name]['huigou']
          }
        })
      },
    }

}
</script>

<style lang="scss" scoped="true">
@import "@/assets/styles/index.sass";
::v-deep{
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      background-image: linear-gradient(to right, #ffffff , #ffffff,#ffffff);
      .van-nav-bar__title{
        font-size: 36px;
        color: #1a1a1a;
      }
    }
  }
  .van-dialog__content{
    text-align: center;
  }
}


.shopping{
  background: #f7f7f7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.topcenter {
  position: relative;
}
.goodsInfo{
  background-color: #ffffff;
  margin-top: 10px;
  h1{
    font-size: 40px;
    font-weight: bolder;
    padding: 0 20px;
  }
  .condition{
    width: 90px;
    padding: 2px 2px;
    margin: 20px 20px;
    border: 1px solid #ff2d2e;
    color: #ff2d2e;
    font-size: 32px;
    text-align: center;
    border-radius: 20px;
  }
  .price{
    display: flex;
    margin: 20px 20px;
    padding-bottom: 20px;
    .priceText{
      color: #d5d5d5;
      font-size: 30px;
      margin-top: 10px;
    }
    .priceNumber{
      color: #c8695e;
      font-weight: bold;
      font-size: 40px;
      margin-left: 20px;
    }
    button{
      width: 200px;
      height: 60px;
      margin-left: auto;
      background: #ea6056;
      margin-right: 20px;
      border: 0;
      border-radius: 50px;
      color: #fff;
      font-size: 36px;
    }
  }
}
.goodsNextInfo{
  display: flex;
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  font-size: 32px;
  color: #777777;
  p:first-child{
    margin: 20px 0 20px 20px;
  }
  p {
    margin: 20px 0 20px 140px;
  }
}
.goodsDetail{
  margin-top: 10px;
  background-color: #ffffff;
  text-align: center;
  h1{
    font-size: 40px;
    font-weight: bolder;
    padding: 20px 20px;
    text-align: left;
  }
  img{
    width: 95%;
  }
}
::v-deep {
  .van-nav-bar__right{
    margin-right: 20px;
    color:#ffffff;
    flex-direction: column;//修改主轴方向
    //flex-wrap: wrap;//开启自动换行
    .van-nav-bar_right_text{
      font-size: 25px;
      height: 24px;
      margin: -20px 0 20px 0;
    }
    .van-nav-bar_right_price {
      font-size: 35px;
      height: 24px;
    }
  }
}
.title {
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.centerbox_dialog {
  margin-top: 10px ;
  text-align: center;
  font-size: 30px;
}
::v-deep.van-dialog {
  width: 500px;
}
::v-deep.van-button {
  display: none;
}
.gocer {
  position: relative;
  font-size: 32px;
  //padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  color: #ffb606;
  text-align: center;
}
</style>