var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shopping" },
    [
      _c("van-nav-bar", {
        attrs: { title: "商城", placeholder: "", fixed: "", border: false }
      }),
      _c("img", {
        staticClass: "image",
        attrs: { src: require("@/assets/images/pic_details_top2@2x.png") }
      }),
      _c("div", { staticClass: "topcenter" }, [
        _c("div", { staticClass: "goodsInfo" }, [
          _c("h1", [_vm._v("足金9999投资黄金")]),
          _c("div", { staticClass: "condition" }, [_vm._v("9999")]),
          _c("div", { staticClass: "price" }, [
            _c("p", { staticClass: "priceText" }, [_vm._v("价格")]),
            _c("p", { staticClass: "priceNumber" }, [
              _vm._v(_vm._s((_vm.pricebox * 100).toFixed(2)) + "元")
            ]),
            _c("button", { on: { click: _vm.goBuy } }, [_vm._v("立即购买")])
          ])
        ]),
        _vm._m(0),
        _vm._m(1)
      ]),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("img", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              src: require("@/assets/images/shopping_kefu.png"),
              alt: ""
            }
          }),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("请添加微信客服咨询购买")
          ]),
          _c("div", {
            staticClass: "gocer",
            on: {
              click: function($event) {
                _vm.ceringModel = false
              }
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goodsNextInfo" }, [
      _c("p", [_vm._v("成色:9999")]),
      _c("p", [_vm._v("金重:100g")]),
      _c("p", [_vm._v("库存:500")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goodsDetail" }, [
      _c("h1", [_vm._v("商品详情")]),
      _c("img", {
        attrs: { src: require("@/assets/images/Group 1026@2x.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }